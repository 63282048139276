<template>
	<main class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>MY 서비스</li>
							<li>나의 서비스</li>
						</ul>
					</div>
					<h2 class="page_title">나의 서비스</h2>
				</div>
			</div>
			<div class="content_body">
				<div class="inner">
					<div class="account_info">
						<b>{{ getSessionProc().emailId }}</b> 님께서 신청한 서비스 이용 정보입니다.
					</div>
					<div class="tbl_basic">
						<table>
							<tr>
								<th>수집 희망 채널</th>
								<td>{{ history.channelList | stringToArrayForService }}</td>
							</tr>
							<tr>
								<th>요금 청구월</th>
								<td>{{ history.month }}</td>
							</tr>
							<tr>
								<th>서비스 당월 요금</th>
								<td>{{ history.monthPrice == 'null' ? 0 : history.monthPrice }}원 (VAT포함)</td>
							</tr>
							<tr>
								<th>추가 비용</th>
								<td>{{ history.addPrice == 'null' ? 0 : history.addPrice }}원 (VAT포함)</td>
							</tr>
							<tr>
								<th>요금 청구 합계</th>
								<td>{{ history.totalPrice == 'null' ? 0 : history.totalPrice }}원 (VAT포함)</td>
							</tr>
							<tr>
								<th>납부 방법</th>
								<td>{{ history.payment | billPaymentFilter }} </td>
							</tr>
							<tr>
								<th>납부 금액</th>
								<td>{{ history.realPayPrice }}원 (VAT포함)</td>
							</tr>
							<tr>
								<th>납부 일자</th>
								<td>{{ history.paymentDate | dateFilter }}</td>
							</tr>
						</table>
					</div>
					<div class="button_area">
						<button type="button" class="btn_list btn_black" @click="goBack">목록</button>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: "historyView",
	data() {
		return {
			idx: this.$route.query.idx,
			history: {},
		}
	},
	mounted() {
		this.getMyHistoryOne()
	},
	methods: {

		goBack() {
			history.back();
		},

		getMyHistoryOne() {
			const self = this;

			let temp = {}
			this.$eventBus.$emit("startProgress")

			temp.idx = this.idx
			temp.emailId = this.getSessionProc().emailId;

			this.axiosCaller.get(this, this.APIs.MY_SERVICE + "/getMyHistoryOne", temp, (res) => {
				var result = res.data
				if (result.status === "ok") {

					if (result.history == null) {
						this.swalUtils.gritter("잘못된 접근입니다.", result.msg, "error").then(() => {
							//self.$router.push('/')
							history.back();
						});
					}

					self.history = result.history;
					console.log(':::;result.service',result.history)

				} else {
					this.swalUtils.gritter("Error", result.msg, "error")
				}
				this.$eventBus.$emit("doneProgress")
			})

		}

	}
}
</script>

<style scoped>

</style>